import { FaDiscord } from "react-icons/fa";

export default function DiscordButton({ className }: { className: string }) {
  return (
    <a
      className={`${className} bg-[#494BF0] h-[60px] w-[180px] rounded-lg flex flex-row items-center px-2 gap-2`}
      href="https://discord.gg/YaGqCzeh"
      target="_blank"
      rel="noreferrer"
    >
      <FaDiscord size={48} color="white" />
      <div className="flex flex-col gap-0.5">
        <span className="text-white text-xs">Join the</span>
        <span className="text-white font-bold text-lg">Discord</span>
      </div>
    </a>
  )
}